// This file is generated by icon spritesheet generator

export type IconName =
  | "YoutubeLogo"
  | "XCircle"
  | "Warning"
  | "WarningTriangle"
  | "WarningCircle"
  | "WaitingList"
  | "Video"
  | "VerticalDots"
  | "Vector"
  | "ValidateCircle"
  | "UserProfile"
  | "TiktokLogo"
  | "Tasks"
  | "Target"
  | "SliderHorizontal"
  | "Sidebar"
  | "Rocket"
  | "Ramdam"
  | "RamdamLogo"
  | "RamdamAi"
  | "QuestionMark"
  | "Product"
  | "Plus"
  | "PlusCircle"
  | "PencilSimple"
  | "OrderCaretUp"
  | "OrderCaretDown"
  | "Megaphone"
  | "MagicWand"
  | "Logout"
  | "List"
  | "ListCheck"
  | "LinkSimple"
  | "LadderSimple"
  | "InstagramLogo"
  | "Info"
  | "InfoBold"
  | "Help"
  | "Files"
  | "FileText"
  | "Duplicate"
  | "Copy"
  | "Compact"
  | "Collabs"
  | "Codeblock"
  | "Close"
  | "ChevronLeft"
  | "CheckCircle"
  | "CheckCircleFilled"
  | "CheckBold"
  | "CaretRight"
  | "CaretDown"
  | "Burger"
  | "Budget"
  | "BookOpen"
  | "ArticleNyTimes"
  | "Archives"
  | "ArrowsCounterClockwise"

export const iconNames = [
  "YoutubeLogo",
  "XCircle",
  "Warning",
  "WarningTriangle",
  "WarningCircle",
  "WaitingList",
  "Video",
  "VerticalDots",
  "Vector",
  "ValidateCircle",
  "UserProfile",
  "TiktokLogo",
  "Tasks",
  "Target",
  "SliderHorizontal",
  "Sidebar",
  "Rocket",
  "Ramdam",
  "RamdamLogo",
  "RamdamAi",
  "QuestionMark",
  "Product",
  "Plus",
  "PlusCircle",
  "PencilSimple",
  "OrderCaretUp",
  "OrderCaretDown",
  "Megaphone",
  "MagicWand",
  "Logout",
  "List",
  "ListCheck",
  "LinkSimple",
  "LadderSimple",
  "InstagramLogo",
  "Info",
  "InfoBold",
  "Help",
  "Files",
  "FileText",
  "Duplicate",
  "Copy",
  "Compact",
  "Collabs",
  "Codeblock",
  "Close",
  "ChevronLeft",
  "CheckCircle",
  "CheckCircleFilled",
  "CheckBold",
  "CaretRight",
  "CaretDown",
  "Burger",
  "Budget",
  "BookOpen",
  "ArticleNyTimes",
  "Archives",
  "ArrowsCounterClockwise",
] as const
